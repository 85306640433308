import React from 'react';
import classnames from 'classnames';
import styles from './stack.mod.module.css';

type StackProps = {
  children: React.ReactNode;
  scale?: 'none' | 'xs' | 's' | 'm' | 'l' | 'xl';
  className?: string;
  alignItems?: 'stretch' | 'flexStart' | 'flexEnd' | 'center';
};

const Stack = ({
  children,
  scale = 's',
  className,
  alignItems = 'stretch',
}: StackProps) => (
  <div className={classnames(styles[scale], styles[alignItems], className)}>
    {children}
  </div>
);

export default Stack;
