import { SET_DRAWER_MENU_STATE } from './constants';

const initialState = {
  isDrawerMenuOpen: false,
};

function drawerMenuReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DRAWER_MENU_STATE:
      return {
        ...state,
        isDrawerMenuOpen: action.payload,
      };
    default:
      return state;
  }
}

export default drawerMenuReducer;
