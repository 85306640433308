import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncUserList = lazy(() => import('~/containers/UserList'));

export default [
  <Route path="/users">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncUserList />
    </Suspense>
  </Route>,
];
