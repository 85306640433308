/**
 *
 * ApplicationSkeleton
 *
 */

import React from 'react';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorPage from '~/components/ErrorPage';
import styles from './styles.module.css';

type ApplicationSkeletonProps = {
  error?: boolean;
};

const ApplicationSkeleton = (props: ApplicationSkeletonProps) => {
  if (props.error) {
    console.error(props.error);
    return <ErrorPage onRetry={() => window.location.reload()} />;
  }
  return (
    <div>
      <AppBar position="static" style={{ height: 64 }} />
      <div className={styles.wrapper}>
        <CircularProgress
          data-testid="circular-progress"
          size={120}
          thickness={7}
        />
      </div>
    </div>
  );
};

export default ApplicationSkeleton;
