import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PublisherEmergencyReportResource } from '~/generated';
import { EmergencyModeService } from '~/generated/services/EmergencyModeService';

export const useEmergencyModePublisher = (
  publisherId: number,
  options: Omit<
    UseQueryOptions<PublisherEmergencyReportResource, unknown>,
    'queryKey'
  > = {}
) =>
  useQuery({
    queryFn: () =>
      EmergencyModeService.emergencyModeGetPublisherEmergencyStatus(
        publisherId
      ),
    retry: false,
    ...options,
    queryKey: ['emergencyMode', 'publisher', publisherId],
  });
