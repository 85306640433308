import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'app.containers.App.close',
    defaultMessage: 'Close',
  },
  loading: {
    id: 'app.containers.App.loading',
    defaultMessage: 'Loading...',
  },
  cancel: {
    id: 'app.containers.App.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'app.containers.App.save',
    defaultMessage: 'Save',
  },
});
