/* eslint-disable no-param-reassign, no-shadow, consistent-return */
import EntitySchema from './schemas/Entity';
import UnionSchema from './schemas/Union';
import ValuesSchema from './schemas/Values';
import ArraySchema, * as ArrayUtils from './schemas/Array';
import ObjectSchema, * as ObjectUtils from './schemas/Object';
import * as ImmutableUtils from './schemas/ImmutableUtils';

const visit = (value, parent, key, schema, addEntity, cache) => {
  if (typeof value !== 'object' || !value) {
    return value;
  }
  if (
    typeof schema === 'object' &&
    (!schema.normalize || typeof schema.normalize !== 'function')
  ) {
    const method = Array.isArray(schema)
      ? ArrayUtils.normalize
      : ObjectUtils.normalize;
    return method(schema, value, parent, key, visit, addEntity, cache);
  }

  return schema.normalize(value, parent, key, visit, addEntity, cache);
};

const addEntities =
  (entities) => (schema, processedEntity, value, parent, key) => {
    const schemaKey = schema.key;
    const id = schema.getId(value, parent, key);
    if (!(schemaKey in entities)) {
      entities[schemaKey] = {};
    }

    const existingEntity = entities[schemaKey][id];
    if (existingEntity) {
      entities[schemaKey][id] = schema.merge(existingEntity, processedEntity);
    } else {
      entities[schemaKey][id] = processedEntity;
    }
  };

export const schema = {
  Array: ArraySchema,
  Entity: EntitySchema,
  Object: ObjectSchema,
  Union: UnionSchema,
  Values: ValuesSchema,
};

export const normalize = (input, schemaArg) => {
  if (!input || typeof input !== 'object') {
    throw new Error(
      `Unexpected input given to normalize. Expected type to be "object", found "${typeof input}".`
    );
  }

  const entities = {};
  const addEntity = addEntities(entities);

  const result = visit(input, input, null, schemaArg, addEntity, {});
  return { entities, result };
};

const unvisitEntity = (input, schemaArg, unvisit, getEntity, cache) => {
  const entity = getEntity(input, schemaArg);
  if (typeof entity !== 'object' || entity === null) {
    return entity;
  }

  const id = schemaArg.getId(entity);

  if (!cache[schemaArg.key]) {
    cache[schemaArg.key] = {};
  }

  if (!cache[schemaArg.key][id]) {
    // Ensure we don't mutate it non-immutable objects
    const entityCopy = ImmutableUtils.isImmutable(entity)
      ? entity
      : { ...entity };

    // Need to set this first so that if it is referenced further within the
    // denormalization the reference will already exist.
    cache[schemaArg.key][id] = entityCopy;
    cache[schemaArg.key][id] = schemaArg.denormalize(entityCopy, unvisit);
  }

  return cache[schemaArg.key][id];
};

const getEntities = (entities) => {
  const isImmutable = ImmutableUtils.isImmutable(entities);

  return (entityOrId, schemaArg) => {
    const schemaKey = schemaArg.key;

    if (typeof entityOrId === 'object') {
      return entityOrId;
    }

    return isImmutable
      ? entities.getIn([schemaKey, entityOrId.toString()])
      : entities[schemaKey][entityOrId] || entityOrId;
  };
};

const getUnvisit = (entities) => {
  const cache = {};
  const getEntity = getEntities(entities);

  return function unvisit(input, schemaArg) {
    if (
      typeof schemaArg === 'object' &&
      (!schemaArg.denormalize || typeof schemaArg.denormalize !== 'function')
    ) {
      const method = Array.isArray(schemaArg)
        ? ArrayUtils.denormalize
        : ObjectUtils.denormalize;
      return method(schemaArg, input, unvisit);
    }

    if (input === undefined || input === null) {
      return input;
    }

    if (schemaArg instanceof EntitySchema) {
      return unvisitEntity(input, schemaArg, unvisit, getEntity, cache);
    }

    return schemaArg.denormalize(input, unvisit);
  };
};

export const denormalize = (input, schemaArg, entities) => {
  if (typeof input !== 'undefined') {
    return getUnvisit(entities)(input, schemaArg);
  }
};
