export const LOGIN_REQUEST = 'app/App/LOGIN_REQUEST';
export const LOGIN_REQUESTED = 'app/App/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'app/App/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'app/App/LOGIN_FAILURE';

export const START_IMPERSONATION_SESSION =
  'app/App/START_IMPERSONATION_SESSION';
export const END_IMPERSONATION_SESSION = 'app/App/END_IMPERSONATION_SESSION';

export const LOGOUT = 'app/App/LOGOUT';

export const CALL_API = 'app/App/CALL_API';

export const STORE_ACCESS_INFORMATION = 'app/App/STORE_ACCESS_INFORMATION';
