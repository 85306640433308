/*
 * ErrorMessage Messages
 *
 * This contains all the text for the ErrorMessage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  CircuitNotFound: {
    id: 'app.components.ErrorMessage.CircuitNotFound',
    defaultMessage: 'Circuit not found',
  },
  LanguageNotUnique: {
    id: 'app.components.ErrorMessage.LanguageNotUnique',
    defaultMessage: 'Language exists already',
  },
  ValidationFailed: {
    id: 'app.components.ErrorMessage.ValidationFailed',
    defaultMessage: 'Validation failed',
  },
  PlanningValidationError: {
    id: 'app.components.ErrorMessage.PlanningValidationError',
    defaultMessage: 'Publishing failed due to planning mistakes',
  },
  ExperienceStateError: {
    id: 'app.components.ErrorMessage.ExperienceStateError',
    defaultMessage: 'Invalid experience state',
  },
  InvalidApplicationState: {
    id: 'app.components.ErrorMessage.InvalidApplicationState',
    defaultMessage: 'Error. The provided application state is not valid.',
  },
  ShiftNotOpenForApplications: {
    id: 'app.components.ErrorMessage.ShiftNotOpenForApplications',
    defaultMessage: 'Error. Shift is not open for applications, yet.',
  },
  PublisherNotActive: {
    id: 'app.components.ErrorMessage.PublisherNotActive',
    defaultMessage: 'Error. Publisher not active',
  },
  CannotUnreleaseShiftWithApplications: {
    id: 'app.components.ErrorMessage.CannotUnreleaseShiftWithApplications',
    defaultMessage:
      'Cannot return to draft mode when shift already has applications',
  },
});
