import omit from 'lodash/omit';
import mergeWith from 'lodash/mergeWith';
import { createSelector } from 'reselect';
import { denormalize } from '~/modules/normalizr';

export const DELETE_ENTITY = 'app/entities/DELETE_ENTITY';

const initialState = {
  routes: {},
  depots: {},
  languages: {},
  congregations: {},
  publishers: {},
  shifts: {},
  turns: {},
  turnReports: {},
  turnAssignments: {},
  shiftApplications: {},
  settings: {},
};

export const selectEntitiesState = () => (state) => state.entities;

export const selectEntity = () => (state, entityType, entityId) => {
  const entitiesState = selectEntitiesState()(state);
  return entitiesState[entityType][entityId];
};

export const selectDenormalizedEntity = (schema) =>
  createSelector(selectEntitiesState(), selectEntity(), (entities, entity) =>
    denormalize(entity, schema, entities)
  );

// eslint-disable-next-line consistent-return
const customizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
};

export default (state = initialState, action) => {
  if (action.type === DELETE_ENTITY && action.meta.entityId) {
    return {
      ...state,
      [action.meta.schema._key]: omit(
        state[action.meta.schema._key],
        action.meta.entityId
      ),
    };
  }
  if (action.payload && action.payload.entities) {
    return mergeWith({}, state, action.payload.entities, customizer);
  }
  return state;
};
