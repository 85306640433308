/*
 *
 * FetchEntities constants
 *
 */

export const INITIALIZE_ENTITY_SLICE =
  'app/FetchEntities/INITIALIZE_ENTITY_SLICE';

export const FETCH_ENTITIES_REQUEST =
  'app/FetchEntities/FETCH_ENTITIES_REQUEST';
export const FETCH_ENTITIES_SUCCESS =
  'app/FetchEntities/FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAILURE =
  'app/FetchEntities/FETCH_ENTITIES_FAILURE';

export const CREATE_ENTITY_REQUEST = 'app/FetchEntities/CREATE_ENTITY_REQUEST';
export const CREATE_ENTITY_SUCCESS = 'app/FetchEntities/CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAILURE = 'app/FetchEntities/CREATE_ENTITY_FAILURE';

export const CHANGE_ENTITY_REQUEST = 'app/FetchEntities/CHANGE_ENTITY_REQUEST';
export const CHANGE_ENTITY_SUCCESS = 'app/FetchEntities/CHANGE_ENTITY_SUCCESS';
export const CHANGE_ENTITY_FAILURE = 'app/FetchEntities/CHANGE_ENTITY_FAILURE';

export const DELETE_ENTITY_REQUEST = 'app/FetchEntities/DELETE_ENTITY_REQUEST';
export const DELETE_ENTITY_SUCCESS = 'app/FetchEntities/DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAILURE = 'app/FetchEntities/DELETE_ENTITY_FAILURE';

export const CONTROLS_POSITION_TOP = 'top';
export const CONTROLS_POSITION_BOTTOM = 'bottom';
