// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import React from 'react';
import { Switch } from 'react-router-dom';

/* Public Route Imports */

import notFoundPageRoutes from '~/containers/NotFoundPage/routes';

/* Private Route Imports */

import createCongregationCreateRoutes from '~/containers/CongregationCreate/routes';
import createCongregationEditRoutes from '~/containers/CongregationEdit/routes';
import createCongregationListRoutes from '~/containers/CongregationList/routes';

import createCircuitCreateRoutes from '~/containers/CircuitCreate/routes';
import createCircuitEditRoutes from '~/containers/CircuitEdit/routes';
import createCircuitListRoutes from '~/containers/CircuitList/routes';

import createDepotCreateRoutes from '~/containers/DepotCreate/routes';
import createDepotEditRoutes from '~/containers/DepotEdit/routes';
import createDepotListRoutes from '~/containers/DepotList/routes';

import createRouteEditRoutes from '~/containers/RouteEdit/routes';
import createRouteCreateRoutes from '~/containers/RouteCreate/routes';
import createRouteListRoutes from '~/containers/RouteList/routes';

import createUserEditRoutes from '~/containers/UserEdit/routes';
import createUserCreateRoutes from '~/containers/UserCreate/routes';
import createUserListRoutes from '~/containers/UserList/routes';
import createAuditEventListRoutes from '~/containers/AuditEventList/routes';

import createDocumentListRoutes from '~/containers/DocumentList/routes';
import createDocumentCreateRoutes from '~/containers/DocumentCreate/routes';
import createDirectoyCreateRoutes from '~/containers/DirectoryCreate/routes';

import createNewsEntryListRoutes from '~/containers/NewsEntryList/routes';
import createNewsEntryCreateRoutes from '~/containers/NewsEntryCreate/routes';
import createNewsEntryEditRoutes from '~/containers/NewsEntryEdit/routes';

import createExperiencesRoutes from '~/containers/Experiences/routes';
import createTurnReportsEditRoutes from '~/containers/TurnReportEdit/routes';

import createWeekplanRoutes from '~/containers/Weekplan/routes';
import createApplicationRoutes from '~/containers/Application/routes';
import createAvailabilityRoutes from '~/containers/Availability/routes';
import createAvailabilityCreateRoutes from '~/containers/AvailabilityCreate/routes';
import createAvailabilityEditRoutes from '~/containers/AvailabilityEdit/routes';

import createAbsenceCreateRoutes from '~/containers/AbsenceCreate/routes';

import createTurnReportListRoutes from '~/containers/TurnReportList/routes';

import userProfileRoutes from '~/containers/UserProfile/routes';

import impressumRoutes from '~/containers/Impressum/routes';
import privacyPolicyRoutes from '~/containers/PrivacyPolicy/routes';
import termsOfUseRoutes from '~/containers/TermsOfUse/routes';

import reportsRoutes from '~/containers/Reports/routes';
import settingsRoutes from './containers/Settings/routes';
import homepageRoutes from './containers/HomePage/routes';

import emergencyModeRoutes from './containers/EmergencyMode/routes';
import emergencyStatusRoutes from './containers/EmergencyStatus/routes';

// const publicRouteCreators = [notFoundPageRoutes];

// export default () => {
//   const createRoutes = (routeCreators) =>
//     routeCreators.reduce(
//       (routes, routeCreator) => [...routes, ...routeCreator()],
//       []
//     );

//   return [
//     // {
//     //   childRoutes: [...createRoutes(privateRouteCreators)],
//     // },
//     ...createRoutes(publicRouteCreators),
//   ];
// };

const nextRoutes = [
  ...reportsRoutes,

  ...settingsRoutes,

  ...homepageRoutes,
  ...termsOfUseRoutes,
  ...privacyPolicyRoutes,
  ...impressumRoutes,

  ...userProfileRoutes,

  ...createApplicationRoutes,

  ...createAvailabilityCreateRoutes,
  ...createAvailabilityEditRoutes,
  ...createAvailabilityRoutes,
  ...createAbsenceCreateRoutes,

  ...createWeekplanRoutes,

  ...createUserCreateRoutes,
  ...createUserEditRoutes,
  ...createUserListRoutes,

  ...createAuditEventListRoutes,

  ...createRouteCreateRoutes,
  ...createRouteEditRoutes,
  ...createRouteListRoutes,

  ...createDepotCreateRoutes,
  ...createDepotEditRoutes,
  ...createDepotListRoutes,

  ...createTurnReportsEditRoutes,
  ...createTurnReportListRoutes,

  ...createExperiencesRoutes,

  ...createNewsEntryCreateRoutes,
  ...createNewsEntryEditRoutes,
  ...createNewsEntryListRoutes,

  ...createDocumentCreateRoutes,
  ...createDirectoyCreateRoutes,
  ...createDocumentListRoutes,

  ...createCircuitCreateRoutes,
  ...createCircuitEditRoutes,
  ...createCircuitListRoutes,

  ...createCongregationCreateRoutes,
  ...createCongregationEditRoutes,
  ...createCongregationListRoutes,

  ...emergencyModeRoutes,
  ...emergencyStatusRoutes,

  ...notFoundPageRoutes,
];

const Routes = () => <Switch>{nextRoutes}</Switch>;

export default Routes;
