/*
 * PublisherNotFound Messages
 *
 * This contains all the text for the PublisherNotFound component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  reason: {
    id: 'app.components.PublisherNotFound.reason',
    defaultMessage:
      'You are trying to access a tenant you are not registered for.',
  },
  headline: {
    id: 'app.components.PublisherNotFound.headline',
    defaultMessage: "You don't have access to this tenant",
  },
});
