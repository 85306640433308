import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncSettings = lazy(() => import('~/containers/Settings'));

const settingsRoutes = [
  <Route path="/settings/:category">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncSettings />
    </Suspense>
  </Route>,
];

export default settingsRoutes;
