/*
 * ContentNotFound Messages
 *
 * This contains all the text for the ContentNotFound component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  reason: {
    id: 'app.components.ContentNotFound.reason',
    defaultMessage:
      "It either been removed, doesn't exist yet, or you don't have access to it anylonger.",
  },
  headline: {
    id: 'app.components.ContentNotFound.headline',
    defaultMessage: 'The content you are looking could not be found.',
  },
  retry: {
    id: 'app.components.ContentNotFound.retry',
    defaultMessage: 'Reload',
  },
});
