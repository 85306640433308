const promisifyApiAction = (getApiAction) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      getApiAction((err, success) => {
        if (err) {
          reject(err);
        } else {
          resolve(success);
        }
      })
    );
  });

export default promisifyApiAction;
