/*
 *
 * FetchEntities reducer
 *
 */
import { DELETE_ENTITY } from '../../entitiesReducer';
import {
  INITIALIZE_ENTITY_SLICE,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAILURE,
  CREATE_ENTITY_REQUEST,
  CREATE_ENTITY_SUCCESS,
  CREATE_ENTITY_FAILURE,
  DELETE_ENTITY_SUCCESS,
} from './constants';

export const initialState = {};

function FetchEntitiesReducer(state = initialState, action) {
  // if there is no instance referenced, this
  // actions can not be handled
  if (!action || !action.meta || !action.meta.instanceName) return state;
  // this action is handleble
  const { instanceName } = action.meta;
  switch (action.type) {
    case INITIALIZE_ENTITY_SLICE:
      return {
        ...state,
        [instanceName]: {
          isFetching: true,
          result: (state[instanceName] && state[instanceName].result) || [],
          totalCount:
            (state[instanceName] && state[instanceName].totalCount) || 0,
        },
      };
    case FETCH_ENTITIES_REQUEST:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          isFetching: true,
        },
      };
    case FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        [instanceName]: {
          isFetching: false,
          result: action.payload.result,
          totalCount: action.payload.totalCount,
        },
      };
    case FETCH_ENTITIES_FAILURE:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          error: action.payload,
          isFetching: false,
        },
      };
    case CREATE_ENTITY_REQUEST:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          isFetching: true,
        },
      };
    case CREATE_ENTITY_SUCCESS:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          result: [...state[instanceName].result, action.payload.result],
          totalCount: action.payload.totalCount,
          isFetching: false,
        },
      };
    case CREATE_ENTITY_FAILURE:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          isFetching: false,
        },
      };
    case DELETE_ENTITY:
    case DELETE_ENTITY_SUCCESS:
      return {
        ...state,
        [instanceName]: {
          ...state[instanceName],
          result: Array.isArray(state[instanceName].result)
            ? state[instanceName].result.filter(
                (id) => id !== action.meta.entityId
              )
            : undefined,
        },
      };
    default:
      return state;
  }
}

export default FetchEntitiesReducer;
