import { SET_DRAWER_MENU_STATE } from './constants';

export const setDrawerMenuState = ({
  isDrawerMenuOpen,
}: {
  isDrawerMenuOpen: boolean;
}) => ({
  type: SET_DRAWER_MENU_STATE,
  payload: isDrawerMenuOpen,
});
