import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import LoadingView from '~/components/LoadingView';

const AsyncNotFoundPage = lazy(() => import('~/containers/NotFoundPage'));

const notFoundPageRoutes = [
  <Route path="/">
    <Suspense fallback={<LoadingView />}>
      <AsyncNotFoundPage />
    </Suspense>
  </Route>,
];

export default notFoundPageRoutes;
