import React from 'react';
import Helmet from 'react-helmet';

// @ts-ignore
import withSettings from '../WithSettings';

type PageTitleProps = {
  isFetchingSettings: boolean;
  settings: {
    environment: {
      tenantName: string;
    };
  };
};

const PageTitle = (props: PageTitleProps) => (
  <Helmet>
    <title>
      {props.isFetchingSettings ? 'MEP' : props.settings.environment.tenantName}
    </title>
  </Helmet>
);

export default withSettings(PageTitle);
