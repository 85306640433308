/*
 *
 * HasRole
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withCurrentPublisher } from '~/containers/WithCurrentPublisher';

// enum of possible roles
export const roles = {
  administrator: 'Administrator',
  turnLeader: 'ShiftLeader',
  vip: 'Vip',
};

export const HasRole = (props) => {
  const hasAnyRequiredRole =
    props.accessInformation.role.filter((userRole) =>
      props.roles.includes(userRole)
    ).length > 0;
  return <>{hasAnyRequiredRole && props.children}</>;
};

HasRole.displayName = 'HasRole';

HasRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(roles))).isRequired,
  accessInformation: PropTypes.shape({
    role: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  children: PropTypes.node.isRequired,
};

export default withCurrentPublisher(HasRole);
