import React from 'react';
import PropTypes from 'prop-types';
import flatpickr from 'flatpickr';
import { de } from 'flatpickr/dist/l10n/de';
import { withCurrentPublisher } from '~/containers/WithCurrentPublisher';

export const flatPickerLocaleMap = {
  de,
  en: undefined, // uses en by default
};

export class ConfigureFlatpickrLocale extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    publisherData: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      publisher: PropTypes.shape({
        uiLanguage: PropTypes.string,
      }),
    }),
  };

  componentDidMount() {
    if (!this.props.publisherData.isFetching) {
      this.initializeFlatpickr(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.publisherData.isFetching) {
      this.initializeFlatpickr(nextProps);
    }
  }

  initializeFlatpickr = (props) => {
    flatpickr.localize(
      flatPickerLocaleMap[props.publisherData.publisher.uiLanguage] || de
    );
  };

  render() {
    return this.props.children;
  }
}

export default withCurrentPublisher(ConfigureFlatpickrLocale);
