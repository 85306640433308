import { createSelector } from 'reselect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectDrawerMenu = () => (state: any) => state.drawerMenu;

export const selectIsDrawerMenuOpen = () =>
  createSelector(
    selectDrawerMenu(),
    (globalState) => globalState.isDrawerMenuOpen
  );
