import React from 'react';
import PropTypes from 'prop-types';
import { getAsyncInjectors } from '~/utils/asyncInjectors';

class InjectReducer extends React.Component {
  static displayName = 'InjectReducer';

  static propTypes = {
    reducer: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const { injectReducer } = getAsyncInjectors(context.store);
    injectReducer(props.name, props.reducer);
  }

  render() {
    return this.props.children;
  }
}

export const injectReducer = (reducerName, reducerFn) => (WrappedComponent) => {
  const ComposedComponent = (props) => (
    <InjectReducer name={reducerName} reducer={reducerFn}>
      <WrappedComponent {...props} />
    </InjectReducer>
  );
  return ComposedComponent;
};

export default InjectReducer;
