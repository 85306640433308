import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const EmergencyStatus = lazy(() => import('~/containers/EmergencyStatus'));

export default [
  <Route path="/emergency-status">
    <Suspense fallback={<ApplicationSkeleton />}>
      <EmergencyStatus />
    </Suspense>
  </Route>,
];
