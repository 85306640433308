/*
 *
 * UserDefinedConfigProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import SentryContainer from '~/containers/Sentry';
import { useUILanguage } from '../UILanguage';

export const UserDefinedConfigProvider = (props) => {
  const { locale } = useUILanguage();
  return (
    <IntlProvider locale={locale} messages={props.messages[locale]}>
      {/* TODO: replave with React.Fragment */}
      <div className="root">
        <SentryContainer>{props.children}</SentryContainer>
      </div>
    </IntlProvider>
  );
};

UserDefinedConfigProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default UserDefinedConfigProvider;
