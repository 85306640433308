/*
 *
 * CurrentAppVersion
 *
 */

import React from 'react';

export const CurrentAppVersion = () => (
  <span>{import.meta.env.VITE_APP_VERSION}</span>
);

CurrentAppVersion.displayName = 'CurrentAppVersion';

export default CurrentAppVersion;
