import React from 'react';
import { FormattedMessage } from 'react-intl';
import Spacings from '~/modules/ui-kit/spacings';
import Text from '~/modules/ui-kit/text';
import messages from './messages';
import styles from './styles.module.css';
import EmptyIcon from './empty-illustration.svg?react';

export const PublisherNotFound = () => (
  <div className={styles.page}>
    <Spacings.Inset>
      <Spacings.Stack scale="l" alignItems="center">
        <EmptyIcon className={styles.icon} />
        <Spacings.Stack alignItems="center">
          <Text type="title" color="secondary">
            <FormattedMessage {...messages.headline} />
          </Text>
          <Text color="secondary">
            <FormattedMessage {...messages.reason} />
          </Text>
        </Spacings.Stack>
      </Spacings.Stack>
    </Spacings.Inset>
  </div>
);

PublisherNotFound.displayName = 'PublisherNotFound';

export default PublisherNotFound;
