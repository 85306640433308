/*
 *
 * FetchDepots actions
 *
 */
import promisifyApiAction from '~/utils/promisify-api-action';
import { CALL_API } from '~/containers/App/constants';

import {
  INITIALIZE_ENTITY_SLICE,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAILURE,
  CREATE_ENTITY_REQUEST,
  CREATE_ENTITY_SUCCESS,
  CREATE_ENTITY_FAILURE,
  CHANGE_ENTITY_REQUEST,
  CHANGE_ENTITY_SUCCESS,
  CHANGE_ENTITY_FAILURE,
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILURE,
} from './constants';

export const initializeEntitySlice = (instanceName) => ({
  type: INITIALIZE_ENTITY_SLICE,
  payload: {
    instanceName,
  },
});

export const fetchEntities = (entityConfig, query, callback) => ({
  type: CALL_API,
  payload: {
    endpoint: query,
    authenticated: true,
    types: [
      FETCH_ENTITIES_REQUEST,
      FETCH_ENTITIES_SUCCESS,
      FETCH_ENTITIES_FAILURE,
    ],
    callback,
  },
  meta: {
    schema: entityConfig.schema,
    instanceName: entityConfig.instanceName,
  },
});

export const createEntity = (entityConfig, entity, callback) => ({
  type: CALL_API,
  payload: {
    endpoint: entityConfig.endpoint,
    authenticated: true,
    types: [
      CREATE_ENTITY_REQUEST,
      CREATE_ENTITY_SUCCESS,
      CREATE_ENTITY_FAILURE,
    ],
    config: {
      method: 'POST',
      body: JSON.stringify(entity),
    },
    callback,
  },
  meta: {
    schema: entityConfig.schema,
    instanceName: entityConfig.instanceName,
  },
});

export const changeEntity = (entityConfig, entity, callback) => ({
  type: CALL_API,
  payload: {
    endpoint: `${entityConfig.endpoint}/${entity.id}`,
    authenticated: true,
    types: [
      CHANGE_ENTITY_REQUEST,
      CHANGE_ENTITY_SUCCESS,
      CHANGE_ENTITY_FAILURE,
    ],
    config: {
      method: 'PUT',
      body: JSON.stringify(entity),
    },
    callback,
  },
  meta: {
    schema: entityConfig.schema,
    entityId: entity.id,
    instanceName: entityConfig.instanceName,
  },
});

export const deleteEntity = (entityConfig, entity) =>
  promisifyApiAction((callback) => ({
    type: CALL_API,
    payload: {
      endpoint: `${entityConfig.endpoint}/${entity.id}`,
      authenticated: true,
      types: [
        DELETE_ENTITY_REQUEST,
        DELETE_ENTITY_SUCCESS,
        DELETE_ENTITY_FAILURE,
      ],
      config: {
        method: 'DELETE',
      },
      callback,
    },
    meta: {
      schema: entityConfig.schema,
      entityId: entity.id,
      instanceName: entityConfig.instanceName,
    },
  }));
