import { SHOW_SNACKBAR } from './constants';

const initialState = { message: '', open: false };

function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        autoHideDuration: 4000,
        open: true,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default snackbarReducer;
