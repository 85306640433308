/*
 *
 * FetchEntities
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import pick from 'lodash/pick';
import ContentNotFound from '~/components/ContentNotFound';
import ErrorPage from '~/components/ErrorPage';
import { injectReducer } from '~/components/InjectReducer';
import * as actions from './actions';
import {
  selectEntities,
  selectIsFetching,
  selectError,
  selectTotalCount,
} from './selectors';
import entitiesReducer from './reducer';
import ApiError from '../../utils/api-error';

// this propTypes are exported seperatly, so that it can be
// imported and used as some kind of "Interface" for wrapping
// containers.
export const FetchInterface = {
  instanceName: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  query: PropTypes.arrayOf(PropTypes.string),
  // filtering
  filter: PropTypes.arrayOf(PropTypes.string),
  // paging`
  page: PropTypes.number,
  perPage: PropTypes.number,
  // sorting
  sort: PropTypes.arrayOf(PropTypes.string),
  // reference expansions
  expand: PropTypes.arrayOf(PropTypes.string),
  // entity type related props
  schema: PropTypes.object.isRequired,
};

export class FetchEntities extends React.Component {
  static propTypes = {
    ...FetchInterface,
    children: PropTypes.func,
    mapEntitiesDataToProps: PropTypes.func,
    autofetch: PropTypes.bool,
    avoidReinitialization: PropTypes.bool,
    component: PropTypes.any,
    ignoreErrors: PropTypes.bool,
    page: PropTypes.number,
    perPage: PropTypes.number,
    filter: PropTypes.arrayOf(PropTypes.string),
    expand: PropTypes.arrayOf(PropTypes.string),
    sort: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.arrayOf(PropTypes.string),
    // connected
    entities: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    isFetching: PropTypes.bool.isRequired,
    // actions
    initializeEntitySlice: PropTypes.func.isRequired,
    fetchEntities: PropTypes.func.isRequired,
    createEntity: PropTypes.func.isRequired,
    changeEntity: PropTypes.func.isRequired,
    deleteEntity: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autofetch: true,
    page: 0,
    perPage: 50,
    filter: [],
    expand: [],
    sort: [],
    query: [],
    mapEntitiesDataToProps: (entitiesData) => ({ entitiesData }),
    ignoreErrors: false,
  };

  componentDidMount() {
    const { instanceName, initializeEntitySlice, autofetch } = this.props;
    const isEntitySliceAlreadyInitialized = this.props.entities.length !== 0;
    if (this.props.avoidReinitialization && isEntitySliceAlreadyInitialized) {
      return;
    }
    initializeEntitySlice(instanceName);
    if (autofetch) this.fetchEntities(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { createQuery, fetchEntities, props } = this;
    // initialize instance slice
    if (nextProps.instanceName !== props.instanceName) {
      nextProps.initializeEntitySlice(nextProps.instanceName);
    }
    if (createQuery(nextProps) !== createQuery(props) && props.autofetch) {
      fetchEntities(nextProps);
    }
  }

  createQuery = (props) => {
    // create filter args
    const filterArgs = props.filter.map(
      (arg) => `filter=${encodeURIComponent(arg)}`
    );
    // create expand args
    const expandArgs = props.expand.map(
      (arg) => `expand=${encodeURIComponent(arg)}`
    );
    // create pagination args
    const paginationArgs = [`page=${props.page}`, `perPage=${props.perPage}`];
    // sorting args
    const sortingArgs = props.sort.map((arg) => `sort=${arg}`);
    // gather args
    const args = [
      ...filterArgs,
      ...paginationArgs,
      ...sortingArgs,
      ...expandArgs,
      ...props.query,
    ];
    // construct query
    return `${props.endpoint}?${args.join('&')}`;
  };

  createActionEntityConfig = (props) =>
    pick(props, ['instanceName', 'endpoint', 'schema']);

  fetchEntities = (props = this.props) =>
    new Promise((resolve, reject) => {
      props.fetchEntities(
        this.createActionEntityConfig(props),
        this.createQuery(props),
        (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
        }
      );
    });

  injectEntityConfig =
    (action) =>
    (...args) =>
      action(this.createActionEntityConfig(this.props), ...args);

  render() {
    const { injectEntityConfig, fetchEntities } = this;
    const Component = this.props.component;
    const dataProps = this.props.mapEntitiesDataToProps(
      {
        entities: this.props.entities,
        isFetching: this.props.isFetching,
        totalCount: this.props.totalCount || 0,
        fetchEntities,
        createEntity: injectEntityConfig(this.props.createEntity),
        changeEntity: injectEntityConfig(this.props.changeEntity),
        deleteEntity: injectEntityConfig(this.props.deleteEntity),
      },
      this.props.childProps
    );
    if (this.props.error && !this.props.ignoreErrors) {
      if (
        this.props.error instanceof ApiError &&
        this.props.error.statusCode === 404
      ) {
        return (
          <ContentNotFound
            isLoading={this.props.isFetching}
            onRetry={() => this.fetchEntities(this.props)}
          />
        );
      }
      return (
        <ErrorPage
          isLoading={this.props.isFetching}
          onRetry={() => this.fetchEntities(this.props)}
        />
      );
    }
    return Component ? (
      <Component {...this.props.childProps} {...dataProps} />
    ) : (
      this.props.children(dataProps.entitiesData)
    );
  }
}

export const entitiesDataShape = {
  entities: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchEntities: PropTypes.func.isRequired,
};

export const createMapStateToProps = () => {
  let entitySelector;
  let fetchingSelector;
  let errorSelector;
  let totalCountSelector;
  let prevProps = {};
  return (state, props) => {
    if (prevProps.instanceName !== props.instanceName) {
      entitySelector = selectEntities(props.instanceName, props.schema);
      fetchingSelector = selectIsFetching(props.instanceName);
      errorSelector = selectError(props.instanceName);
      totalCountSelector = selectTotalCount(props.instanceName);
    }
    prevProps = props;
    return {
      entities: entitySelector(state, props.instanceName, props.schema._key),
      error: errorSelector(state),
      isFetching: fetchingSelector(state, props.instanceName),
      totalCount: totalCountSelector(state, props.instanceName),
    };
  };
};

const mapDispatchToProps = {
  initializeEntitySlice: actions.initializeEntitySlice,
  fetchEntities: actions.fetchEntities,
  createEntity: actions.createEntity,
  changeEntity: actions.changeEntity,
  deleteEntity: actions.deleteEntity,
};

const ConnectedFetchEntities = compose(
  injectReducer('fetchEntities', entitiesReducer),
  connect(createMapStateToProps, mapDispatchToProps)
)(FetchEntities);

export default ConnectedFetchEntities;

export const fetchEntities =
  (getProps, mapEntitiesDataToProps, options) =>
  (ComposedComponent) =>
  (childProps) => {
    const props = getProps(childProps);
    return (
      <ConnectedFetchEntities
        mapEntitiesDataToProps={mapEntitiesDataToProps}
        childProps={childProps}
        {...props}
        {...options}
        component={ComposedComponent}
      />
    );
  };
