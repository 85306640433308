/**
 *
 * Sentry
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as SentryReact from '@sentry/react';

class Sentry extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    children: null,
  };

  UNSAFE_componentWillMount() {
    SentryReact.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: import.meta.env.VITE_APP_VERSION,
      enabled: import.meta.env.PROD,
    });
    SentryReact.setUser({
      email: this.props.user.email,
      id: this.props.user.id,
      languages: this.props.user.languages,
    });
  }

  render() {
    return this.props.children;
  }
}

export default Sentry;
