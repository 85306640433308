import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const Text = (props) => (
  <span
    style={props.style}
    className={classnames(
      styles.text,
      styles[props.type],
      !props.inheritColor && styles[props.color],
      {
        [styles['on-dark']]: props.onDark,
      },
      props.className
    )}
  >
    {props.children}
  </span>
);

Text.displayName = 'Text';

Text.propTypes = {
  type: PropTypes.oneOf([
    'display2',
    'display1',
    'headline',
    'title',
    'subheading',
    'body2',
    'body1',
    'caption',
    'label',
  ]),
  color: PropTypes.oneOf(['primary', 'secondary', 'label', 'error']),
  onDark: PropTypes.bool,
  inheritColor: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

Text.defaultProps = {
  type: 'body1',
  color: 'primary',
  onDark: false,
  inheritColor: false,
};

export default Text;
