import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mui/styles';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemButton } from '@mui/material';

export const ExpandableListItem = (props) => {
  const [expanded, setExpanded] = React.useState(!!props.open);
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      <ListItemButton onClick={toggleExpanded}>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <ListItemText
          primary={props.primaryLabel}
          secondary={props.secondaryLabel}
        />
        <ExpandMoreIcon
          color="action"
          style={{
            transform: `rotate(${expanded ? '180' : '0'}deg)`,
            transition: 'transform 300ms',
          }}
        />
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {React.Children.map(props.children, (childItem) =>
            React.cloneElement(childItem, {
              style: {
                ...childItem.style,
                paddingLeft: props.theme.spacing(4),
              },
            })
          )}
        </List>
      </Collapse>
    </>
  );
};

ExpandableListItem.displayName = 'ExpandableListItem';

ExpandableListItem.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.node,
  primaryLabel: PropTypes.node,
  secondaryLabel: PropTypes.node,
  children: PropTypes.node,
  theme: PropTypes.shape({
    spacing: PropTypes.func.isRequired,
  }).isRequired,
};

export default withTheme(ExpandableListItem);
