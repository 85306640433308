/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import reduxCatch from 'redux-catch';
// @ts-ignore
import createReducer from './reducers';
// @ts-ignore
import appSagas from './containers/App/sagas';

const devtools =
  window.__REDUX_DEVTOOLS_EXTENSION__ || (() => (noop: unknown) => noop);

type State = Record<string, unknown>;

export default function configureStore(initialState = {}): Store<State> {
  const sagaMiddleware = createSagaMiddleware({
    onError(error) {
      if (import.meta.env.PROD) {
        Sentry.captureException(error);
      }
    },
  });
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [thunk, sagaMiddleware];

  if (import.meta.env.PROD) {
    middlewares.push(reduxCatch((error) => Sentry.captureException(error)));
  }

  const enhancers = [applyMiddleware(...middlewares), devtools()];

  const store = createStore(
    createReducer(),
    initialState,
    compose(...enhancers)
  );

  appSagas.map(sagaMiddleware.run);

  // Create hook for async sagas
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (store as any).runSaga = sagaMiddleware.run;

  // Initialize it with no other reducers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (store as any).asyncReducers = {};
  return store;
}
