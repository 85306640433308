/*
 *
 * WithProfile
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import schemas from '~/schemas';
import { fetchEntities } from '~/containers/FetchEntities';
import withCurrentPublisher from '~/containers/WithCurrentPublisher';

export const WithProfile = (props) => {
  const { profileData, childProps } = props;
  const Component = props.component;

  return <Component {...childProps} profileData={profileData} />;
};

WithProfile.displayName = 'WithProfile';

WithProfile.propTypes = {
  profileData: PropTypes.any,
  component: PropTypes.any,
  childProps: PropTypes.any,
};

export const mapPropsToFetchProfileParams = ({ publisherData }) => ({
  instanceName: 'profile',
  schema: schemas.PROFILE,
  endpoint: `Publishers/${publisherData.publisher.id}/profile`,
});

export const ComposedWithProfile = compose(
  withCurrentPublisher,
  fetchEntities(
    mapPropsToFetchProfileParams,
    (dataProps) => ({
      profileData: {
        isFetching: dataProps.isFetching,
        profile: dataProps.isFetching ? undefined : dataProps.entities,
      },
    }),
    { ignoreErrors: true }
  )
)(WithProfile);

export const withProfile = (ComposedComponent) => (childProps) => (
  <ComposedWithProfile childProps={childProps} component={ComposedComponent} />
);

export default withProfile;
