import React, { Suspense, lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';
import { useEmergencyModePublisher } from '~/hooks/api/useEmergencyModePublisher';
import { usePublisherMe } from '~/hooks/api/usePublisherMe';

const AsyncHomePage = lazy(() => import('~/containers/HomePage'));

const EmergencyStatusRedirect = () => {
  const { data: publisher } = usePublisherMe();
  const { data, isLoading } = useEmergencyModePublisher(publisher?.id || 0, {
    enabled: !!publisher,
  });
  if (isLoading) {
    return <ApplicationSkeleton />;
  }
  return data ? <Redirect to="/emergency-status" /> : <Redirect to="/home" />;
};

const homepageRoutes = [
  <Route path="/" exact>
    <EmergencyStatusRedirect />
  </Route>,
  <Route path="/home" exact>
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncHomePage />
    </Suspense>
  </Route>,
];

export default homepageRoutes;
