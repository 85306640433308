/*
 *
 * WithSettings
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import omit from 'lodash/omit';

import schemas from '~/schemas';
import { fetchEntities } from '~/containers/FetchEntities';

const createSettingsMap = (settingsData) =>
  settingsData
    ? settingsData.reduce(
        (SettingsKeyedByCategory, settingCategory) => ({
          ...SettingsKeyedByCategory,
          [settingCategory.category]: omit(settingCategory, 'category'),
        }),
        {}
      )
    : {};

export const WithSettings = (props) => {
  const { settingsData, childProps } = props;
  const Component = props.component;
  return (
    <Component
      {...childProps}
      settings={createSettingsMap(settingsData.settings)}
      isFetchingSettings={settingsData.isFetching}
    />
  );
};

WithSettings.displayName = 'WithSettings';

WithSettings.propTypes = {
  settingsData: PropTypes.any,
  component: PropTypes.any,
  childProps: PropTypes.any,
};

export const mapPropsToFetchSettingsParams = () => ({
  instanceName: 'settings',
  schema: schemas.SETTING,
  endpoint: `Settings`,
});

export const ComposedWithSettings = compose(
  fetchEntities(
    mapPropsToFetchSettingsParams,
    (dataProps) => ({
      settingsData: {
        isFetching: dataProps.isFetching,
        settings: dataProps.isFetching ? undefined : dataProps.entities,
      },
    }),
    { ignoreErrors: true }
  )
)(WithSettings);

export const withSettings = (ComposedComponent) => (childProps) => (
  <ComposedWithSettings childProps={childProps} component={ComposedComponent} />
);

export default withSettings;
