/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
/* eslint-disable import/first */

// eslint-disable-next-line import/no-extraneous-dependencies
import '@total-typescript/ts-reset';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom/client';
import { OpenAPI } from '~/generated';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import * as configs from '~/configs';
import UserDefinedConfigProvider from './containers/UserDefinedConfigProvider';
import App from './containers/App';
import configureStore from './store';

// Import the CSS reset, which HtmlWebpackPlugin transfers to the build folder
import 'sanitize.css';
import './global.css';

// Import i18n messages
import { translationMessages } from './i18n';
import { UILanguageProvider } from './containers/UILanguage';
import ErrorPage from './components/ErrorPage';

const store = configureStore({});

OpenAPI.BASE = import.meta.env.PROD
  ? configs.production.api.slice(0, -1)
  : window.location.origin + configs.development.api.slice(0, -1);

const queryClient = new QueryClient();

const render = (translatedMessages) => {
  ReactDOM.createRoot(document.getElementById('root')).render(
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <UILanguageProvider>
          <UserDefinedConfigProvider messages={translatedMessages}>
            <Sentry.ErrorBoundary fallback={<ErrorPage />}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Router>
                  <App />
                </Router>
              </LocalizationProvider>
            </Sentry.ErrorBoundary>
          </UserDefinedConfigProvider>
        </UILanguageProvider>
      </Provider>
    </QueryClientProvider>
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/de')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
