/*
 *
 * Snackbar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MaterialSnackbar from '@mui/material/Snackbar';

import { showSnackbar } from './actions';
import * as selectors from './selectors';

export const Snackbar = ({ snackbar, showSnackbar: showSnackbarAction }) => (
  <MaterialSnackbar
    autoHideDuration={2000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    onClose={() => {
      showSnackbarAction({ open: false });
    }}
    {...snackbar}
  />
);

Snackbar.propTypes = {
  snackbar: PropTypes.object.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showSnackbar,
};

const mapStateToProps = createStructuredSelector({
  snackbar: selectors.selectSnackbar(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
