// @ts-ignore
import { schema } from '~/modules/normalizr';

const recurringApplications = new schema.Entity('recurringApplications');
const file = new schema.Entity('file');
const directory = new schema.Entity('directory');
directory.define({
  subDirectories: [directory],
  files: [file],
});
const auditEvent = new schema.Entity('auditEvent');
const newsEntry = new schema.Entity('news');
const profile = new schema.Object({});
const setting = new schema.Entity('settings', {}, { idAttribute: 'category' });
const depot = new schema.Entity('depots');
const language = new schema.Entity('languages', undefined, {
  idAttribute: 'key',
});
const applicationCalendarEvent = new schema.Entity(
  'applicationCalendarEvent',
  {},
  { idAttribute: (value: { shift: { id: number } }) => value.shift.id }
);
const applicationCalendar = new schema.Array(applicationCalendarEvent);
const circuit = new schema.Entity('circuits', {
  language,
});
const congregation = new schema.Entity('congregations');
const publisher = new schema.Entity('publishers');
const route = new schema.Entity('routes', {
  assignablePublishers: [publisher],
  depot,
});
publisher.define({
  languages: [language],
  partner: publisher,
  congregation,
});
const turnAssignment = new schema.Entity('turnAssignments', {
  publisher,
});
const turn = new schema.Entity('turns');
const experienceEvent = new schema.Entity('experienceEvents');
const experience = new schema.Entity('experiences', {
  turn,
  events: [experienceEvent],
});
const turnReport = new schema.Entity('turnReports', {
  turn,
});
const absence = new schema.Entity('absence');
const shiftApplication = new schema.Entity('shiftApplications');
const shift = new schema.Entity('shifts', {
  applications: [shiftApplication],
  turns: [turn],
});
const candidate = new schema.Entity(
  'candidates',
  {
    application: shiftApplication,
    assignment: turnAssignment,
    applicationsForThisDay: [shiftApplication],
    applicationsOfPartnerForThisDay: [shiftApplication],
  },
  {
    idAttribute: (value: { application: { id: number } }, parent: unknown) =>
      // parent is not set when denormalizing. In the normalized state the id
      // is stored in `application` directly
      parent ? value.application.id : value.application,
  }
);
const issue = {
  turn,
  issues: [
    {
      relatedAssignments: [turnAssignment],
      relatedApplications: [shiftApplication],
    },
  ],
};
const dashboard = {};
shiftApplication.define({
  turn,
  plannedTurn: turn,
  shift,
  publisher,
});
turn.define({
  route,
  shift,
  turnAssignments: [turnAssignment],
  language,
  turnReport,
});

const missingShiftLeaders = {};

export default {
  ABSENCE: absence,
  AUDIT_EVENT: auditEvent,
  APPLICATION_CALENDAR: applicationCalendar,
  APPLICATION_CALENDAR_EVENT: applicationCalendarEvent,
  CANDIDATE: candidate,
  CIRCUIT: circuit,
  ROUTE: route,
  DEPOT: depot,
  PUBLISHER: publisher,
  PROFILE: profile,
  LANGUAGE: language,
  CONGREGATION: congregation,
  SHIFT: shift,
  TURN: turn,
  TURN_REPORT: turnReport,
  SHIFT_APPLICATION: shiftApplication,
  EXPERIENCE: experience,
  EXPERIENCE_EVENT: experienceEvent,
  TURN_ASSIGNMENT: turnAssignment,
  ISSUE: issue,
  DASHBOARD: dashboard,
  SETTING: setting,
  NEWS_ENTRY: newsEntry,
  FILE: file,
  DIRECTORY: directory,
  MISSING_SHIFT_LEADERS: missingShiftLeaders,
  RECURRING_APPLICATIONS: recurringApplications,
};
