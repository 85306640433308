import React, { Suspense, lazy } from 'react';
import moment from 'moment';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';
import { Redirect, Route } from 'react-router-dom';

const AsyncRouteAssignment = lazy(
  () => import('~/containers/RouteAssignment/index')
);
const AsyncWeekplan = lazy(() => import('~/containers/Weekplan'));

export default [
  <Redirect
    from="/weekplan"
    exact
    to={{
      pathname: `/weekplan/${moment().isoWeekYear()}/${moment().isoWeek()}/${moment().weekday()}`,
    }}
  />,
  <Route path="/weekplan/assignment/:shiftId/:turnId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncRouteAssignment />
    </Suspense>
  </Route>,
  <Route path="/weekplan/:year/:week/:day">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncWeekplan />
    </Suspense>
  </Route>,
];
