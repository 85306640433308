import React from 'react';
import PropTypes from 'prop-types';
import Sentry from '~/components/Sentry';
import { withProfile } from '~/containers/WithProfile';

const SentryContainer = (props) => (
  <>
    {props.profileData &&
    !props.profileData.isFetching &&
    props.profileData.profile.supportsSentryErrorTracking ? (
      <>
        <Sentry user={props.profileData.profile} />
      </>
    ) : null}
    {props.children}
  </>
);

SentryContainer.propTypes = {
  profileData: PropTypes.shape({
    profile: PropTypes.shape({
      uiLanguage: PropTypes.string,
      supportsSentryErrorTracking: PropTypes.bool,
    }),
    isFetching: PropTypes.bool,
  }),
  children: PropTypes.node,
};

export default withProfile(SentryContainer);
