import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncExperiences = lazy(() => import('~/containers/Experiences'));
const AsyncCreateExperience = lazy(
  () => import('~/containers/Experiences/CreateExperience')
);
const AsyncExperienceDetails = lazy(
  () => import('~/containers/Experiences/ExperienceDetails')
);
const AsyncCreateEvent = lazy(
  () => import('~/containers/Experiences/CreateEvent')
);
const AsyncEditTitle = lazy(() => import('~/containers/Experiences/EditTitle'));
const AsyncEditEvent = lazy(() => import('~/containers/Experiences/EditEvent'));
const AsyncExperiencesList = lazy(() => import('~/containers/ExperienceList'));

export default [
  <Route path="/experienceList">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncExperiencesList />
    </Suspense>
  </Route>,
  <Route path="/experiences/new">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncCreateExperience />
    </Suspense>
  </Route>,
  <Route path="/experiences/:experienceId/add-event">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncCreateEvent />
    </Suspense>
  </Route>,
  <Route path="/experiences/:experienceId/edit-title">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncEditTitle />
    </Suspense>
  </Route>,
  <Route path="/experiences/:experienceId/:eventId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncEditEvent />
    </Suspense>
  </Route>,
  <Route path="/experiences/:experienceId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncExperienceDetails />
    </Suspense>
  </Route>,
  <Route path="/experiences">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncExperiences />
    </Suspense>
  </Route>,
];
