import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PublisherResource } from '~/generated';
import { PublishersService } from '~/generated/services';

export const usePublisherMe = (
  options: Omit<UseQueryOptions<PublisherResource, unknown>, 'queryKey'> = {}
) =>
  useQuery({
    queryFn: () => PublishersService.publishersGetMe(),
    queryKey: ['publishers', 'me'],
    ...options,
  });
