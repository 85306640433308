import React from 'react';
import SubmissionError from 'redux-form/lib/SubmissionError';
import ErrorMessage from '~/components/ErrorMessage';

export function ApiError({ errors } = { errors: {} }, statusCode = 500) {
  this.name = 'ApiError';
  this.errors = errors;
  this.statusCode = statusCode;
  this.stack = new Error().stack;
  this.getGeneralErrorCode = () => {
    const generalError = this.errors && this.errors._error;
    if (!generalError) {
      return undefined;
    }
    if (!Array.isArray(generalError) || generalError.length === 0) {
      throw new Error('Invalid API error');
    }
    return generalError[0].code;
  };
  this.toReduxFormError = () => {
    // If for whatever reason the server returns e.g. a 404 when submitting
    // a form there won't be any errors in the response. The errors object
    // is only present for 422 errors.
    if (!this.errors) return new SubmissionError();
    const reduxFormError = Object.keys(this.errors).reduce(
      (accErrors, errorKey) => {
        const fieldErrors = this.errors[errorKey];
        if (!Array.isArray(fieldErrors) || fieldErrors.length === 0) {
          throw new Error('Invalid API error');
        }
        // At the moment we only ever display one error even though there might
        // be more. There is no strong reason to do so other than it is simpler
        // do display in the UI
        const error = fieldErrors[0];
        return {
          ...accErrors,
          [errorKey]: <ErrorMessage fallbackMessage={error} />,
        };
      },
      {}
    );
    return new SubmissionError(reduxFormError);
  };
}

export default ApiError;
