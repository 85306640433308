import React, { Suspense, lazy } from 'react';
import moment from 'moment';
import LoadingView from '~/components/LoadingView';
import { Redirect, Route } from 'react-router-dom';

const AsyncReports = lazy(() => import('~/containers/Reports'));

const ReportsRoutes = [
  <Route
    path="/reports/:reportType"
    render={({ match, location }) => {
      const query = new URLSearchParams(location.search);
      return (
        <>
          {match.params.reportType === 'circuit' && !query.has('year') ? (
            <Redirect
              to={{
                // show the report of the previous quarter, as it is only available a
                // couple of days after the quarter ends
                pathname: '/reports/circuit',
                search: `?${new URLSearchParams({
                  year: moment().subtract(1, 'quarter').year(),
                  quarter: moment().subtract(1, 'quarter').quarter(),
                }).toString()}`,
              }}
            />
          ) : null}
          {match.params.reportType === 'branch' && !query.has('year') ? (
            <Redirect
              to={{
                // show the report of the previous month, as it is only available a
                // couple of days after the month ends
                pathname: '/reports/branch',
                search: `?${new URLSearchParams({
                  year: moment().subtract(1, 'month').year(),
                  month: moment().subtract(1, 'month').month() + 1,
                }).toString()}`,
              }}
            />
          ) : null}
          {match.params.reportType === 'month' && !query.has('year') ? (
            <Redirect
              to={{
                // show the month report of the current month
                pathname: '/reports/month',
                search: `?${new URLSearchParams({
                  year: moment().year(),
                  month: moment().month() + 1,
                }).toString()}`,
              }}
            />
          ) : null}
          <Suspense fallback={<LoadingView onGray hideLabel />}>
            <AsyncReports />
          </Suspense>
        </>
      );
    }}
  />,
];

export default ReportsRoutes;
