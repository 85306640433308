import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './inset.mod.module.css';

type InsetProps = {
  scale?: 'xs' | 's' | 'm' | 'l' | 'xl';
  children: React.ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

const Inset = ({ children, scale = 'm', className, ...props }: InsetProps) => (
  <div className={classnames(styles[scale], className)} {...props}>
    {children}
  </div>
);

export default Inset;
