/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyResource } from '../models/EmergencyResource';
import type { EnterEmergencyModeParam } from '../models/EnterEmergencyModeParam';
import type { PublisherEmergencyReportResource } from '../models/PublisherEmergencyReportResource';
import type { UpdatePublisherEmergencyStatusParam } from '../models/UpdatePublisherEmergencyStatusParam';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmergencyModeService {
    /**
     * @returns EmergencyResource
     * @throws ApiError
     */
    public static emergencyModeCurrent(): CancelablePromise<EmergencyResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/EmergencyMode',
        });
    }
    /**
     * @param requestBody
     * @returns EmergencyResource
     * @throws ApiError
     */
    public static emergencyModeEnterEmergencyMode(
        requestBody: EnterEmergencyModeParam,
    ): CancelablePromise<EmergencyResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/EmergencyMode',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static emergencyModeTerminateEmergencyMode(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/EmergencyMode',
        });
    }
    /**
     * @returns PublisherEmergencyReportResource
     * @throws ApiError
     */
    public static emergencyModeGetPublisherEmergencyReports(): CancelablePromise<Array<PublisherEmergencyReportResource>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/EmergencyMode/publisher-emergency-reports',
        });
    }
    /**
     * @param publisherId
     * @returns void
     * @throws ApiError
     */
    public static emergencyModeAcknowledgePost(
        publisherId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/EmergencyMode/publisher-emergency-reports/publisher/{publisherId}/acknowledgement',
            path: {
                'publisherId': publisherId,
            },
        });
    }
    /**
     * @param publisherId
     * @returns void
     * @throws ApiError
     */
    public static emergencyModeReportAsSafePost(
        publisherId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/EmergencyMode/publisher-emergency-reports/publisher/{publisherId}/safe',
            path: {
                'publisherId': publisherId,
            },
        });
    }
    /**
     * @param publisherId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static emergencyModeUpdatePublisherEmergencyStatus(
        publisherId: number,
        requestBody: UpdatePublisherEmergencyStatusParam,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/EmergencyMode/publisher-emergency-reports/publisher/{publisherId}/status',
            path: {
                'publisherId': publisherId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param publisherId
     * @returns PublisherEmergencyReportResource
     * @throws ApiError
     */
    public static emergencyModeGetPublisherEmergencyStatus(
        publisherId: number,
    ): CancelablePromise<PublisherEmergencyReportResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/EmergencyMode/publisher-emergency-reports/publisher/{publisherId}',
            path: {
                'publisherId': publisherId,
            },
        });
    }
    /**
     * @param hash
     * @returns binary
     * @throws ApiError
     */
    public static emergencyModeAcknowledgeGet(
        hash: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/em/ack/{hash}',
            path: {
                'hash': hash,
            },
        });
    }
    /**
     * @param hash
     * @returns binary
     * @throws ApiError
     */
    public static emergencyModeReportAsSafeGet(
        hash: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/em/safe/{hash}',
            path: {
                'hash': hash,
            },
        });
    }
}
