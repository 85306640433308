import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncDepotList = lazy(() => import('~/containers/DepotList'));

export default [
  <Route path="/depots">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncDepotList />
    </Suspense>
  </Route>,
];
