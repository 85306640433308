/*
 * ErrorPage Messages
 *
 * This contains all the text for the ErrorPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ErrorPage.header',
    defaultMessage:
      'We are sad to report that something went wrong while loading this page 😢',
  },
  retry: {
    id: 'app.components.ErrorPage.retry',
    defaultMessage: 'Reload',
  },
});
