import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncCongregationEdit = lazy(
  () => import('~/containers/CongregationEdit')
);

export default [
  <Route path="/congregations/:congregationId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncCongregationEdit />
    </Suspense>
  </Route>,
];
