import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AbsenceCreate = lazy(() => import('~/containers/AbsenceCreate'));

export default [
  <Route path="/absences/create">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AbsenceCreate />
    </Suspense>
  </Route>,
];
