import React, { Suspense, lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncDocumentList = lazy(() => import('~/containers/DocumentList'));

export default [
  <Route path="/documents/:directoryId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncDocumentList />
    </Suspense>
  </Route>,
  <Redirect from="/documents" exact to="/documents/0" />,
];
