import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

type ErrorMessageProps = {
  errorCode: string;
  fallbackMessage: React.ReactNode;
};

export const ErrorMessage = (props: ErrorMessageProps) =>
  Object.keys(messages).includes(props.errorCode) ? (
    <FormattedMessage {...messages[props.errorCode as keyof typeof messages]} />
  ) : (
    props.fallbackMessage
  );

export default ErrorMessage;
