/*
 *
 * WithCurrentPublisher
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import schemas from '~/schemas';
import { fetchEntities } from '~/containers/FetchEntities';
import PublisherNotFound from '~/components/PublisherNotFound';

export const WithCurrentPublisher = (props) => {
  const { publisherData, childProps } = props;
  const Component = props.component;

  if (publisherData.isFetching) {
    return null;
  }

  if (!publisherData.publisher || publisherData.publisher.length === 0) {
    return <PublisherNotFound />;
  }

  const role = [];
  if (publisherData.publisher.isAdministrator) {
    role.push('Administrator');
  }
  if (publisherData.publisher.isVip) {
    role.push('Vip');
  }
  if (publisherData.publisher.isPublisher) {
    role.push('Publisher');
  }
  if (publisherData.publisher.isShiftLeader) {
    role.push('ShiftLeader');
  }

  return (
    <Component
      {...childProps}
      accessInformation={{
        publisherId: publisherData.publisher.id,
        role,
      }}
      publisherData={publisherData}
    />
  );
};

WithCurrentPublisher.displayName = 'WithCurrentPublisher';

WithCurrentPublisher.propTypes = {
  publisherData: PropTypes.any,
  component: PropTypes.any,
  childProps: PropTypes.any,
};

export const mapPropsToFetchPublisherParams = () => ({
  instanceName: 'currentPublisher',
  expand: ['congregation', 'languages', 'partner'],
  schema: schemas.PUBLISHER,
  endpoint: `Publishers/my`,
});

export const ComposedWithCurrentPublisher = compose(
  fetchEntities(
    mapPropsToFetchPublisherParams,
    (dataProps) => ({
      publisherData: {
        isFetching: dataProps.isFetching,
        publisher:
          dataProps.isFetching && Array.isArray(dataProps.entities)
            ? undefined
            : dataProps.entities,
      },
    }),
    { avoidReinitialization: true, ignoreErrors: true }
  )
)(WithCurrentPublisher);

export const withCurrentPublisher = (ComposedComponent) => (childProps) => (
  <ComposedWithCurrentPublisher
    childProps={childProps}
    component={ComposedComponent}
  />
);

export default withCurrentPublisher;
