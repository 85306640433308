import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncNewsEntryEdit = lazy(() => import('~/containers/NewsEntryEdit'));

export default [
  <Route path="/news-entries/:newsEntryId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncNewsEntryEdit />
    </Suspense>
  </Route>,
];
