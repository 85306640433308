import React, { Suspense, lazy } from 'react';
import moment from 'moment';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';
import { Redirect, Route } from 'react-router-dom';

const Application = lazy(() => import('~/containers/Application'));

export default [
  <Redirect
    from="/application"
    exact
    to={{
      pathname: `/application/${moment().isoWeekYear()}/${moment().isoWeek()}/${moment().weekday()}`,
    }}
  />,
  <Route path="/application/:year/:week/:day">
    <Suspense fallback={<ApplicationSkeleton />}>
      <Application />
    </Suspense>
  </Route>,
];
