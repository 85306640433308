import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncCircuitEdit = lazy(() => import('./index'));

export default [
  <Route path="/circuits/:circuitId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncCircuitEdit />
    </Suspense>
  </Route>,
];
