import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AvailabilityEdit = lazy(() => import('~/containers/AvailabilityEdit'));

export default [
  <Route path="/availability/:publisherId/:recurrentApplicationId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AvailabilityEdit />
    </Suspense>
  </Route>,
  <Route path="/availability/:recurrentApplicationId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AvailabilityEdit />
    </Suspense>
  </Route>,
];
