import { createSelector } from 'reselect';
import { denormalize } from '~/modules/normalizr';
import { selectEntitiesState } from '../../entitiesReducer';

export const selectFetchEntitiesState = () => (state) => state.fetchEntities;

export const selectInstanceFetchSlice = (instanceName) => (state) => {
  const fetchSlice = state.fetchEntities;
  return fetchSlice && fetchSlice[instanceName];
};

export const selectEntities = (instanceName, schema) =>
  createSelector(
    selectEntitiesState(),
    selectInstanceFetchSlice(instanceName),
    (entities, fetchSlice) => {
      if (fetchSlice && fetchSlice.result != null) {
        if (Array.isArray(fetchSlice.result)) {
          return denormalize(fetchSlice.result, [schema], entities);
        }
        return denormalize(fetchSlice.result, schema, entities);
      }
      return [];
    }
  );

export const selectIsFetching = (instanceName) =>
  createSelector(selectInstanceFetchSlice(instanceName), (fetchSlice) =>
    fetchSlice ? fetchSlice.isFetching : true
  );

export const selectTotalCount = (instanceName) =>
  createSelector(selectInstanceFetchSlice(instanceName), (fetchSlice) =>
    fetchSlice ? fetchSlice.totalCount : 0
  );

export const selectError = (instanceName) =>
  createSelector(selectInstanceFetchSlice(instanceName), (fetchSlice) =>
    fetchSlice ? fetchSlice.error : undefined
  );
