import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncDocumentCreate = lazy(() => import('~/containers/DocumentCreate'));

export default [
  <Route path="/documents/:directoryId/newFile">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncDocumentCreate />
    </Suspense>
  </Route>,
];
