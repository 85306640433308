import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AvailabilityCreate = lazy(
  () => import('~/containers/AvailabilityCreate')
);

export default [
  <Route path="/availability/create">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AvailabilityCreate />
    </Suspense>
  </Route>,
];
