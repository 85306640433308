/*
 * DrawerMenu Messages
 *
 * This contains all the text for the DrawerMenu component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  administration: {
    id: 'app.components.DrawerMenu.administration',
    defaultMessage: 'Administration',
  },
  dashboard: {
    id: 'app.components.DrawerMenu.dashboard',
    defaultMessage: 'Dashboard',
  },
  calendar: {
    id: 'app.components.DrawerMenu.calendar',
    defaultMessage: 'Calendar',
  },
  availability: {
    id: 'app.components.DrawerMenu.availability',
    defaultMessage: 'My Availability',
  },
  experiences: {
    id: 'app.components.DrawerMenu.experiences',
    defaultMessage: 'Experiences',
  },
  turnReports: {
    id: 'app.components.DrawerMenu.turnReports',
    defaultMessage: 'Turn Reports',
  },
  routeList: {
    id: 'app.components.DrawerMenu.routeList',
    defaultMessage: 'Routes',
  },
  depotManagement: {
    id: 'app.components.DrawerMenu.depotManagement',
    defaultMessage: 'Depots',
  },
  circuitManagement: {
    id: 'app.components.DrawerMenu.circuitManagement',
    defaultMessage: 'Circuits',
  },
  congregationManagement: {
    id: 'app.components.DrawerMenu.congregationManagement',
    defaultMessage: 'Congregations',
  },
  languageManagement: {
    id: 'app.components.DrawerMenu.languageManagement',
    defaultMessage: 'Languages',
  },
  userManagement: {
    id: 'app.components.DrawerMenu.userManagement',
    defaultMessage: 'Publishers',
  },
  newsEntryManagement: {
    id: 'app.components.DrawerMenu.newsEntryManagement',
    defaultMessage: 'News',
  },
  logout: {
    id: 'app.components.DrawerMenu.logout',
    defaultMessage: 'Logout',
  },
  impressum: {
    id: 'app.components.DrawerMenu.impressum',
    defaultMessage: 'Impressum',
  },
  terms: {
    id: 'app.components.DrawerMenu.terms',
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    id: 'app.components.DrawerMenu.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  weekplan: {
    id: 'app.components.DrawerMenu.weekplan',
    defaultMessage: 'Weekplan',
  },
  appVersion: {
    id: 'app.components.DrawerMenu.appVersion',
    defaultMessage: 'v{version}',
  },
  userProfile: {
    id: 'app.components.DrawerMenu.userProfile',
    defaultMessage: 'Profile',
  },
  documents: {
    id: 'app.components.DrawerMenu.documents',
    defaultMessage: 'Documents',
  },
  reports: {
    id: 'app.components.DrawerMenu.reports',
    defaultMessage: 'Reports',
  },
  settings: {
    id: 'app.components.DrawerMenu.settings',
    defaultMessage: 'Settings',
  },
  planningScheduleSettings: {
    id: 'app.components.DrawerMenu.planningScheduleSettings',
    defaultMessage: 'Automatic Planning Schedule',
  },
  planningSettings: {
    id: 'app.components.DrawerMenu.planningSettings',
    defaultMessage: 'Planning',
  },
  newsSettings: {
    id: 'app.components.DrawerMenu.newsSettings',
    defaultMessage: 'News',
  },
  reportingSettings: {
    id: 'app.components.DrawerMenu.reportingSettings',
    defaultMessage: 'Reporting',
  },
  environmentSettings: {
    id: 'app.components.DrawerMenu.environmentSettings',
    defaultMessage: 'Environment',
  },
  smtpSettings: {
    id: 'app.components.DrawerMenu.smtpSettings',
    defaultMessage: 'Email settings',
  },
  masterdataSettings: {
    id: 'app.components.DrawerMenu.masterdataSettings',
    defaultMessage: 'Master data',
  },
});
