import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncUserEdit = lazy(() => import('~/containers/UserEdit'));
const AsyncAuditEventList = lazy(() => import('~/containers/AuditEventList'));

export default [
  <Route path="/users/:publisherId/audit">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncAuditEventList />
    </Suspense>
  </Route>,
  <Route path="/users/:publisherId">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncUserEdit />
    </Suspense>
  </Route>,
];
