import React from 'react';
import classnames from 'classnames';
import styles from './inline.mod.module.css';

type InlineProps = {
  children: React.ReactNode;
  scale?: 'xs' | 's' | 'm' | 'l' | 'xl';
  className?: string;
  alignItems?: 'stretch' | 'flexStart' | 'flexEnd' | 'center';
  justifyContent?: 'normal' | 'stretch' | 'flexStart' | 'flexEnd' | 'center';
  style?: React.CSSProperties;
};

const Inline = ({
  children,
  scale = 's',
  alignItems = 'flexStart',
  justifyContent = 'normal',
  className,
  style,
}: InlineProps) => (
  <div
    className={classnames(
      styles[scale],
      styles[`align-items-${alignItems}`],
      styles[`justify-content-${justifyContent}`],
      className
    )}
    style={style}
  >
    {children}
  </div>
);

export default Inline;
