import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncDepotCreate = lazy(() => import('~/containers/DepotCreate'));

export default [
  <Route path="/depots/create">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncDepotCreate />
    </Suspense>
  </Route>,
];
