/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

import deTranslationMessages from './translations/de.json';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';
import frTranslationMessages from './translations/fr.json';
import ruTranslationMessages from './translations/ru.json';

export const appLocales = ['en', 'de', 'fr', 'es', 'ru'] as const;

export const translationMessages = {
  de: deTranslationMessages,
  en: enTranslationMessages,
  es: esTranslationMessages,
  fr: frTranslationMessages,
  ru: ruTranslationMessages,
};
