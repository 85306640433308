import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncUserProfile = lazy(() => import('~/containers/UserProfile'));

export default [
  <Route path="/user-profile">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncUserProfile />
    </Suspense>
  </Route>,
];
