import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import commonMessages from '~/containers/App/messages';
import Spacings from '~/modules/ui-kit/spacings';
import { Typography, useTheme } from '@mui/material';
import messages from './messages';
import styles from './styles.module.css';
import EmptyIcon from './empty-illustration.svg?react';

type ContentNotFoundProps = {
  onRetry?: () => void;
  isLoading?: boolean;
};

export const ContentNotFound = (props: ContentNotFoundProps) => {
  const theme = useTheme();
  return (
    <div className={styles.errorPage}>
      <Spacings.Inset>
        <Spacings.Stack scale="l" alignItems="center">
          <EmptyIcon className={styles.icon} />
          <Spacings.Stack alignItems="center">
            <Typography variant="h6" color="textSecondary">
              <FormattedMessage {...messages.headline} />
            </Typography>
            <Typography color="textSecondary">
              <FormattedMessage {...messages.reason} />
            </Typography>
          </Spacings.Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onRetry}
            startIcon={<RefreshIcon />}
            disabled={props.isLoading}
            style={{ marginTop: theme.spacing(2) }}
          >
            {props.isLoading ? (
              <FormattedMessage {...commonMessages.loading} />
            ) : (
              <FormattedMessage {...messages.retry} />
            )}
          </Button>
        </Spacings.Stack>
      </Spacings.Inset>
    </div>
  );
};

export default ContentNotFound;
